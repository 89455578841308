import React from "react"

import SEO from "../components/seo"
import SmoothScrollbar from "../components/scrollbar"

import "../styles/404.scss"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <SmoothScrollbar>
      <main className="error-page-wrapper">
        <h1>NOT FOUND</h1>
      </main>
    </SmoothScrollbar>
  </>
)

export default NotFoundPage
